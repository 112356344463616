import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import capitalize from 'lodash/capitalize';
import Select from '../Select';
import Icon from '../Icon';
import { ICONS } from '../../constants';
import { sortBy } from '../../helpers';

const LanguageSwitcher = ({
  currentLocale,
  languages,
  mobile,
  label,
  help,
  className,
  onChange,
  intl,
  ...props
}) => {
  const languageOptions = languages
    .map(({ value }) => ({
      label: capitalize(intl.formatDisplayName(value, { type: 'language' })),
      value,
    }))
    .sort(sortBy('label'));

  return (
    <Select
      className={className}
      showLabel={mobile}
      label={label}
      help={help}
      left={prop => <Icon name={ICONS.GLOBE} {...prop} />}
      roundCorners={!mobile}
      tertiary={!mobile}
      basic={mobile}
      value={currentLocale}
      options={languageOptions}
      onChange={({ target: { value } }) => onChange(value)}
      {...props}
    />
  );
};

LanguageSwitcher.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  label: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  help: PropTypes.string,
  className: PropTypes.string,
  intl: PropTypes.shape().isRequired,
};

LanguageSwitcher.defaultProps = {
  mobile: false,
  label: 'Language',
  help: undefined,
  className: undefined,
};

const mapState = state => ({
  languages: state.config.languages,
  currentLocale: state.intl.locale,
});

export default compose(injectIntl, connect(mapState))(LanguageSwitcher);
