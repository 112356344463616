import React from 'react';

const GoGiftLogo = () => (
  <svg viewBox="0 0 72 72">
    <g fillRule="nonzero" fill="none">
      <path
        d="M72 36c0 19.822-16.032 36-36 36C16.178 72 0 55.968 0 36S16.032 0 36 0c19.822 0 36 16.178 36 36"
        fill="#00a1df"
      />
      <path
        d="M24.923 42.121c1.603 0 2.77-1.166 2.77-3.206 0-1.895-1.167-3.06-2.77-3.06s-2.77 1.311-2.77 3.06c0 1.895 1.167 3.206 2.77 3.206zm0-7.578c2.332 0 4.227 1.748 4.227 4.372 0 2.623-1.75 4.372-4.227 4.372-2.478 0-4.227-1.749-4.227-4.372 0-2.624 1.895-4.372 4.227-4.372zm21.862.437h.438v8.016h-3.061V34.98h2.623zm17.928 1.166c0 .874-.73 1.749-1.75 1.749-.874 0-1.748-.729-1.748-1.75 0-.874.728-1.748 1.749-1.748 1.02 0 1.749.729 1.749 1.749zm-45.474-.875V42.85h-.146c-1.312.437-2.332.729-4.372.729-4.81 0-7.58-3.207-7.58-7.288 0-4.372 3.061-7.578 7.142-7.578 1.604 0 3.207.583 4.664 1.749l.292.291-1.02 1.166-.292-.291c-.874-.875-2.186-1.458-3.644-1.458-3.352 0-5.538 2.624-5.538 5.976 0 3.935 2.623 5.975 5.976 5.975 1.749 0 2.769-.437 2.915-.437v-4.81H14.138v-1.603h5.101zm41.684 6.122l.437.728-.291.146c0 .146-.146.146-.146.146-.437.291-1.457.729-2.478.729-1.603 0-3.06-.875-3.06-2.915v-7.288h3.06v2.04h1.75v2.478h-1.75V39.644c0 .583.146.728.438.728.145 0 .437-.145.583-.291l.583-.292.145.146v.292c.292.291.438.728.73 1.166zm-7.87-6.413h1.166v2.477h-2.186V42.996H48.68v-8.599c0-1.75.437-4.956 3.935-4.956 1.166 0 2.332.583 2.915 1.166l-1.749 2.04c-.291-.145-.583-.29-.874-.29-.875 0-.875 1.02-.875 1.748v.875h1.02zm-10.64 0h-.437v7.287c0 .146 0 .146-.146.146-.875.437-2.624.874-4.373.874-4.955 0-7.578-3.06-7.578-7.433 0-3.935 2.915-7.433 7.287-7.433 1.457 0 2.915.437 4.081 1.166.291.292.729.583 1.02.875l-1.895 2.332c-.874-.73-2.04-1.166-3.06-1.166-2.478 0-3.935 2.04-3.935 4.663 0 2.478 1.603 4.373 4.226 4.373 1.166 0 1.603-.292 1.603-.292v-2.915H36.73V34.98h5.684z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default GoGiftLogo;
